<template>
  <!-- 账号添加页面 -->
  <moe-page title="账号添加">
    <moe-form ref="accountForm" :model="account" :rules="accountRules">
      <el-form-item label="账号" prop="account">
        <el-input
          v-model.trim="account.account"
          placeholder="请输入账号"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model.trim="account.name"
          placeholder="请输入姓名"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="密码" prop="pwd">
        <el-input
          v-model.trim="account.pwd"
          type="password"
          placeholder="请输入密码"
          maxlength="50"
          clearable
          show-password
        />
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <el-select
          v-model="role"
          placeholder="请选择角色"
          filterable
          clearable
          value-key="id"
          @change="(e) => (account.roleId = e.id)"
        >
          <el-option
            :label="item.name"
            :value="item"
            v-for="item of roleList"
            :key="item.id"
          />
        </el-select>
        <div style="margin-top: 10px">
          <el-input
            :value="role.info"
            type="textarea"
            placeholder="角色描述"
            :rows="5"
            readonly
          />
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="account.state">
          <el-radio :label="0">启用</el-radio>
          <el-radio :label="-1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="accountLoad"
          @click="accountInfoSubmit"
        >
          {{ accountLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'AccountAdd',
  data() {
    //账号信息
    const account = {
      account: '', //账号
      name: '', //姓名
      pwd: '', //密码
      roleId: '', //角色
      state: 0, //状态
    };

    //账号数据校验
    const verify = this.$moe_verify.verifyForm;
    const accountRules = {
      account: verify.isAccount([
        '请输入账号',
        '账号格式为 字母或数字组成，长度为1~30',
      ]),
      name: verify.isExtent(['请输入姓名', '长度不能超过 1 ~ 10'], '1~10'),
      pwd: verify.isPassword([
        '请输入密码',
        '密码格式为 字母或数字组成，长度为6~16',
      ]),
      roleId: verify.isEmpty('请选择角色'),
      state: verify.isEmpty('请选择状态'),
    };

    return {
      account, //账号信息
      accountRules, //账号数据校验
      role: {}, //角色信息
      roleList: [], //角色列表
      accountLoad: false, //提交状态
    };
  },
  created() {
    //获取所有角色信息
    this.getAllRoleList();
  },
  methods: {
    /**
     * 获取所有角色信息
     **/
    getAllRoleList() {
      // 调用所有角色API
      this.$moe_api.ROLE_API.roleListAll().then((data) => {
        if (data.code == 200) {
          this.roleList = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 提交账号信息
     **/
    accountInfoSubmit() {
      // 表单校验
      this.$refs['accountForm'].validate(() => {
        this.accountLoad = true;
        this.$moe_api.ACCOUNT_API.accountAdd(this.account)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.accountLoad = false;
          });
      });
    },
  },
};
</script>
